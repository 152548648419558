/* Start Sharing buttons */

footer a.fb-share-button span {
    vertical-align: inherit !important;
}

.ssb_totalcount {
    font-family: 'Open Sans', sans-serif;
}

.ssb_t_nb::after {
    color: #777777;
    content: "����������";
    display: block;
    font-size: 11px;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-top: -5px;
}

.ssb_links.metro-retina {
    word-wrap: normal !important;
    clear: both;
    margin: 1em 0;
}

    .ssb_links.metro-retina ul,
    .ssb_links.metro-retina li {
        padding: 0 !important;
        margin: 0 !important;
        list-style: none !important;
    }

        .ssb_links.metro-retina li,
        .ssb_links.metro-retina li a, .ssb_links.metro-retina li a .ssb_icon,
        .ssb_links.metro-retina li a, .ssb_links.metro-retina li a .ssb_network_name {
            display: inline-block;
            vertical-align: middle;
        }

            .ssb_links.metro-retina li a {
                padding: 5px 12px 6px 12px;
                margin: 2px 5px 2px 0;
                border: 0px solid #ddd;
                color: #fff !important;
                text-decoration: none;
                font-size: 14px;
                font-family: "Segoe WP", "Segoe UI", Helvetica, Arial, sans-serif;
                background-clip: padding-box;
            }

    .ssb_links.metro-retina .ssb_icon {
        width: 21px;
        height: 21px;
        vertical-align: -4px;
    }

    .ssb_links.metro-retina .ssb_link_twitter .ssb_icon {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDJweCIgaGVpZ2h0PSI0MnB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQyIDQyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0zNywxMS4wNzZjLTEuMTc3LDAuNTIxLTIuNDQxLDAuODc1LTMuNzcxLDEuMDM0YzEuMzU2LTAuODEzLDIuMzk3LTIuMSwyLjg4OC0zLjYzMw0KCWMtMS4yNjksMC43NTItMi42NzUsMS4yOTktNC4xNjksMS41OTRjLTEuMTk3LTEuMjc2LTIuOTA0LTIuMDc0LTQuNzkzLTIuMDc0Yy0zLjYyNSwwLTYuNTY0LDIuOTM5LTYuNTY0LDYuNTY0DQoJYzAsMC41MTYsMC4wNTcsMS4wMTcsMC4xNywxLjQ5N2MtNS40NTctMC4yNzQtMTAuMjk0LTIuODg4LTEzLjUzMS02Ljg1OWMtMC41NjUsMC45Ny0wLjg5LDIuMDk4LTAuODksMy4zMDENCgljMCwyLjI3OCwxLjE1OSw0LjI4NywyLjkyLDUuNDY0Yy0xLjA3NS0wLjAzNC0yLjA4OC0wLjMyOS0yLjk3My0wLjgyMWMtMC4wMDEsMC4wMjctMC4wMDEsMC4wNTYtMC4wMDEsMC4wODINCgljMCwzLjE4MiwyLjI2Myw1LjgzNSw1LjI2Nyw2LjQzOGMtMC41NTEsMC4xNDktMS4xMzEsMC4yMjktMS43MjksMC4yMjljLTAuNDI0LDAtMC44MzQtMC4wNDEtMS4yMzUtMC4xMTYNCgljMC44MzUsMi42MDcsMy4yNjEsNC41MDYsNi4xMzMsNC41NTljLTIuMjQ3LDEuNzYxLTUuMDc3LDIuODEtOC4xNTMsMi44MWMtMC41MywwLTEuMDUzLTAuMDMtMS41NjYtMC4wOTINCgljMi45MDQsMS44NjMsNi4zNTUsMi45NSwxMC4wNjMsMi45NWMxMi4wNzgsMCwxOC42ODEtMTAuMDA0LDE4LjY4MS0xOC42NzljMC0wLjI4NS0wLjAwNy0wLjU2OC0wLjAxOS0wLjg1MQ0KCUMzNS4wMDcsMTMuNTQ5LDM2LjEyLDEyLjM5MiwzNywxMS4wNzYiLz4NCjwvc3ZnPg0K);
        background-size: 21px 21px;
        background-repeat: no-repeat;
    }

    .ssb_links.metro-retina .ssb_link_google .ssb_icon {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyIDQyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48Zz48cGF0aCBpZD0iWE1MSURfMl8iIGNsYXNzPSJzdDAiIGQ9Ik0xLDIwLjRDMS4xLDEzLjksNyw4LjMsMTMuNSw4LjVjMy4xLTAuMSw2LDEuMiw4LjQsMy4xYy0xLDEuMS0yLjEsMi4zLTMuMiwzLjNjLTIuOC0yLTYuOS0yLjUtOS43LTAuM2MtNC4xLDIuOC00LjMsOS40LTAuMywxMi41YzMuOCwzLjUsMTEsMS43LDEyLTMuNWMtMi40LDAtNC44LDAtNy4yLTAuMWMwLTEuNCwwLTIuOCwwLTQuM2M0LDAsOCwwLDEyLDBjMC4yLDMuMy0wLjIsNi45LTIuMyw5LjdjLTMuMSw0LjQtOS40LDUuNy0xNC4yLDMuOEM0LjEsMzAuOCwwLjYsMjUuNiwxLDIwLjR6Ii8+PHBhdGggaWQ9IlhNTElEXzFfIiBjbGFzcz0ic3QwIiBkPSJNMzIuNywxNC44YzEuNCwwLDIuOCwwLDQuMSwwYzAsMS40LDAsMi44LDAsNC4yYzEuNCwwLDIuOCwwLDQuMiwwYzAsMS40LDAsMi44LDAsNC4xYy0xLjQsMC0yLjgsMC00LjIsMGMwLDEuNCwwLDIuOCwwLDQuMmMtMS40LDAtMi44LDAtNC4xLDBjMC0xLjQsMC0yLjgsMC00LjFjLTEuNCwwLTIuOCwwLTQuMiwwYzAtMS40LDAtMi44LDAtNC4xYzEuNCwwLDIuOCwwLDQuMiwwQzMyLjcsMTcuNSwzMi43LDE2LjEsMzIuNywxNC44eiIvPjwvZz48L3N2Zz4=);
        background-size: 21px 21px;
        background-repeat: no-repeat;
    }

    .ssb_links.metro-retina .ssb_link_facebook .ssb_icon {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDJweCIgaGVpZ2h0PSI0MnB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQyIDQyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNi40MDUsMjIuNjg0VjM3aDYuOTY0VjIyLjc1Mmg0Ljc1NGwxLjE1LTUuOTRoLTUuODU0di00LjM1N2MwLTAuNjAyLDAuMzIxLTEuNDA2LDEuMjk2LTEuNDA2SDI4LjFWNQ0KCWgtNi4zMmMtMi4xOCwwLTUuMzk3LDIuNjg1LTUuMzk3LDUuNTR2Ni4xMDNoLTMuNjU0djYuMDE2TDE2LjQwNSwyMi42ODR6Ii8+DQo8L3N2Zz4NCg==);
        background-size: 21px 21px;
        background-repeat: no-repeat;
    }

    .ssb_links.metro-retina .ssb_link_linkedin .ssb_icon {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDJweCIgaGVpZ2h0PSI0MnB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQyIDQyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMi4yNDYsOC42NDRjMCwxLjg5LTEuMzk2LDMuNDItMy42OTEsMy40MmMtMi4xNiwwLTMuNTU1LTEuNTMtMy41NTUtMy40MmMwLTEuOTM1LDEuNDQyLTMuNDIxLDMuNjQ2LTMuNDIxDQoJQzEwLjg1LDUuMjIzLDEyLjIwMSw2LjcwOSwxMi4yNDYsOC42NDQgTTUuMTgyLDE0Ljc2Nmg2Ljg0djIyLjAwOWgtNi44NFYxNC43NjZ6IE0xNi4xMTYsMjEuNzg3YzAtMi43NDYtMC4wOS01LjA0MS0wLjE4MS03LjAyMQ0KCWg1Ljk0bDAuMzE3LDMuMDYzaDAuMTM1YzAuODk4LTEuNDQyLDMuMTA1LTMuNTU3LDYuNzk2LTMuNTU3YzQuNSwwLDcuODc2LDMuMDE2LDcuODc2LDkuNDk3djEzLjAwOWgtNi44NDFWMjQuNTc4DQoJYzAtMi44MzQtMC45OTItNC43NzEtMy40NjctNC43NzFjLTEuODksMC0zLjAxMywxLjMwNi0zLjUwOSwyLjU2NWMtMC4xODIsMC40NDktMC4yMjcsMS4wOC0wLjIyNywxLjcxMXYxMi42OTNoLTYuODQxVjIxLjc4N3oiLz4NCjwvc3ZnPg0K);
        background-size: 21px 21px;
        background-repeat: no-repeat;
    }

    .ssb_links.metro-retina a:hover,
    .ssb_links.metro-retina a:focus {
        color: #FFF !important;
        background: -moz-linear-gradient(top, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.25)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0) 100%); /* W3C */
        background-clip: padding-box;
    }

    .ssb_links.metro-retina .ssb_link_twitter a,
    .ssb_links.metro-retina .ssb_link_twitter a:hover,
    .ssb_links.metro-retina .ssb_link_twitter a:focus {
        border: 0px solid #0092ba;
        background-color: #59d1df;
    }

    .ssb_links.metro-retina .ssb_link_google a,
    .ssb_links.metro-retina .ssb_link_google a:hover,
    .ssb_links.metro-retina .ssb_link_google a:focus {
        border: 0px solid #ab2b1d;
        background-color: #EA4335;
    }

    .ssb_links.metro-retina .ssb_link_facebook a,
    .ssb_links.metro-retina .ssb_link_facebook a:hover,
    .ssb_links.metro-retina .ssb_link_facebook a:focus {
        border: 0px solid #314b83;
        background-color: #3b5999;
    }

    .ssb_links.metro-retina .ssb_link_linkedin a,
    .ssb_links.metro-retina .ssb_link_linkedin a:hover,
    .ssb_links.metro-retina .ssb_link_linkedin a:focus {
        border: 0px solid #278cc0;
        background-color: #2ba3e1;
    }

    .ssb_links.metro-retina .ssb_counter,
    .ssb_links.metro-retina .ssb_totalcount {
        position: relative;
        right: -2px;
        top: 1px;
        display: inline-block;
        padding: 9px 5px 8px;
        border: 1px solid #ddd;
        border-right-width: 0;
        vertical-align: 0;
        font-size: 11px;
        color: #999;
        background: #fff;
    }

    .ssb_links.metro-retina .ssb_totalcount {
        border-right-width: 1px;
    }

    .ssb_links.metro-retina .ssb_t_l_big {
        border: 0px !important;
        border-right-width: 0px !important;
        font-size: 21px !important;
        font-weight: bold;
        margin-right: 10px;
    }

    .ssb_links.metro-retina .ssb_counter_insidename {
        padding-left: 5px;
        font-weight: bold;
    }

    .ssb_links.metro-retina .ssb_totalcount {
        background-color: transparent;
        background: none;
    }

    .ssb_links.metro-retina .ssb_totalcount {
        border: 0px;
        color: #444;
    }

    .ssb_links.metro-retina li a .ssb_network_name {
        margin-left: 3px;
    }

/* End Sharing buttons */

/* Start Social Profiles Buttons */

@font-face {
    font-family: essb-socialfollowers;
    src: url('Custom/Fonts/essb-socialfollowers.woff');
    src: url('Custom/Fonts/essb-socialfollowers.eot#iefix') format('embedded-opentype'), url('Custom/Fonts/essb-socialfollowers.woff') format('woff'), url('Custom/Fonts/essb-socialfollowers.ttf') format('truetype'), url('Custom/Fonts/essb-socialfollowers.svg#essb-socialfollowers') format('svg');
    font-weight: 400;
    font-style: normal;
}

.essbfc-container {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    clear: both;
    padding: 0;
    margin-bottom: 20px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

    .essbfc-container ul {
        overflow: hidden;
        line-height: 0 !important;
    }

    .essbfc-container li,
    .essbfc-container ul {
        list-style: none !important;
        margin: 0 !important;
        padding: 0 !important;
        background: 0 0 !important;
        text-align: left;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        border: 0 !important;
    }

        .essbfc-container li .essbfc-network,
        .essbfc-container li .essbfc-network i {
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            -ms-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }

    .essbfc-container li {
        line-height: 15px;
    }

        .essbfc-container li .essbfc-network {
            margin: 1px !important;
        }

    .essbfc-container.essbfc-nospace li .essbfc-network {
        margin: 0 !important;
        font-family: 'Open Sans', sans-serif;
    }

    .essbfc-container li:after,
    .essbfc-container li:before {
        content: "" !important;
    }

    .essbfc-container li a {
        text-decoration: none;
    }

.essbfc-network {
    display: block;
    overflow: hidden;
    text-align: center;
}

.essbfc-container.essbfc-col-1 li {
    width: 100% !important;
}

.essbfc-container.essbfc-col-2 li {
    width: 50% !important;
    display: inline-block;
}

.essbfc-container.essbfc-col-3 li {
    width: 33.333% !important;
    display: inline-block;
}

.essbfc-container.essbfc-col-4 li {
    width: 25% !important;
    display: inline-block;
}

.essbfc-container.essbfc-col-5 li {
    width: 20% !important;
    display: inline-block;
}

.essbfc-container.essbfc-col-6 li {
    width: 16.6666% !important;
    display: inline-block;
}

.essbfc-container.essbfc-col-row li {
    width: 100px;
    display: inline-block;
}

.essbfc-container.essbfc-col-1 li .essbfc-icon {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
    font-size: 21px;
}

.essbfc-container.essbfc-col-1 li .essbfc-followers-count {
    float: right;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 700;
}

.essbfc-container.essbfc-col-1 li .essbfc-followers-text {
    float: right;
    text-align: right;
    clear: both;
    margin-top: -20px;
    padding-right: 10px;
}

.essbfc-template-metro li .essbfc-network,
.essbfc-template-metro li a,
.essbfc-template-metro li:hover .essbfc-network,
.essbfc-template-metro li:hover a,
.essbfc-template-tinycolor li .essbfc-network,
.essbfc-template-tinycolor li a,
.essbfc-template-tinycolor li:hover .essbfc-network,
.essbfc-template-tinycolor li:hover a,
.essbfc-template-tinygrey li .essbfc-network,
.essbfc-template-tinygrey li a,
.essbfc-template-tinygrey li:hover .essbfc-network,
.essbfc-template-tinygrey li:hover a {
    color: #FFF !important;
}

.essbfc-template-metro li:hover .essbfc-network {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity 80);
    -moz-opacity: .8;
    -khtml-opacity: .8;
    opacity: .8;
}

.essbfc-template-metro .essbfc-mailchimp .essbfc-network {
    background-color: #2c9ab7 !important;
}

.essbfc-template-metro .essbfc-youtube .essbfc-network {
    background-color: #CD332D !important;
}

.essbfc-template-metro .essbfc-email .essbfc-network {
    background-color: #393939 !important;
}

.essbfc-template-metro .essbfc-twitter .essbfc-network {
    background-color: #4099FF !important;
}

.essbfc-template-metro .essbfc-facebook .essbfc-network {
    background-color: #3B5998 !important;
}

.essbfc-template-metro .essbfc-google .essbfc-network {
    background-color: #dd4b39 !important;
}

.essbfc-template-metro .essbfc-pinterest .essbfc-network {
    background-color: #cb2027 !important;
}

.essbfc-template-metro .essbfc-linkedin .essbfc-network {
    background-color: #007bb6 !important;
}

.essbfc-template-metro .essbfc-instagram .essbfc-network {
    background-color: #3f729b !important;
}

.essbfc-icon {
    font-family: essb-socialfollowers;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-decoration: inherit;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: normal;
    font-size: 38px;
    display: block;
    padding: 10px 0 0;
}

.essbfc-followers-count,
.essbfc-followers-text {
    text-transform: uppercase;
    display: block;
    line-height: normal;
}

.essbfc-followers-count {
    font-size: 24px;
    padding-top: 0;
    min-width: 80px;
    margin-bottom: 0;
}

.essbfc-followers-text {
    font-size: 11px;
    margin-bottom: 7px;
}

.essbfc-totalastext {
    font-weight: 700;
}

.essbfc-icon-google:before {
    content: '\e800';
}

.essbfc-icon-facebook:before {
    content: '\e801';
}

.essbfc-icon-twitter:before {
    content: '\e802';
}

.essbfc-icon-pinterest:before {
    content: '\e803';
}

.essbfc-icon-linkedin:before {
    content: '\e804';
}

.essbfc-icon-mailchimp:before {
    content: '\e808';
}

.essbfc-icon-instagram:before {
    content: '\e80d';
}

.essbfc-icon-youtube:before {
    content: '\e80e';
}

.essbfc-network:hover i.essbfc-icon-pulse {
    -webkit-animation-name: hvr-icon-pulse;
    animation-name: hvr-icon-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.essbfc-network:hover i.essbfc-icon-down {
    -webkit-animation-name: hvr-icon-down;
    animation-name: hvr-icon-down;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

.essbfc-network:hover i.essbfc-icon-up {
    -webkit-animation-name: hvr-icon-up;
    animation-name: hvr-icon-up;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

.essbfc-network:hover i.essbfc-icon-pulse-grow {
    -webkit-animation-name: hvr-icon-pulse-grow;
    animation-name: hvr-icon-pulse-grow;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.essbfc-network:hover i.essbfc-icon-pop {
    -webkit-animation-name: hvr-icon-pop;
    animation-name: hvr-icon-pop;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.essbfc-network:hover i.essbfc-icon-wobble-horizontal {
    -webkit-animation-name: hvr-icon-wobble-horizontal;
    animation-name: hvr-icon-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.essbfc-network:hover i.essbfc-icon-wobble-vertical {
    -webkit-animation-name: hvr-icon-wobble-vertical;
    animation-name: hvr-icon-wobble-vertical;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.essbfc-network:hover i.essbfc-icon-buzz-out {
    -webkit-animation-name: hvr-icon-buzz-out;
    animation-name: hvr-icon-buzz-out;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}